import { render, staticRenderFns } from "./courseMain.vue?vue&type=template&id=3615aa86&scoped=true"
import script from "./courseMain.vue?vue&type=script&lang=js"
export * from "./courseMain.vue?vue&type=script&lang=js"
import style0 from "./courseMain.vue?vue&type=style&index=0&id=3615aa86&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3615aa86",
  null
  
)

export default component.exports