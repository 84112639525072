<template>
  <div class="wxq_container">
    <el-form>
      <div class="wxq_filter">
        <el-radio-group v-model="form.fileGroup" @change="getTableList">
          <el-radio :label="2">视频库</el-radio>
          <el-radio :label="1">文档库</el-radio>
        </el-radio-group>
      </div>
      <div class="wxq_storeroom">
        <div class="wxq_screen">
          <el-date-picker
            v-model="form.startTime"
            type="datetime"
            placement="bottom-start"
            placeholder="选择开始时间"
          >
          </el-date-picker>
          <el-date-picker
            v-model="form.endTime"
            type="datetime"
            placement="bottom-start"
            placeholder="选择结束时间"
          >
          </el-date-picker>
          <el-input
            placeholder="请输入关键字"
            prefix-icon="el-icon-search"
            v-model="form.keyword"
          >
          </el-input>
          <el-button class="btn btn_blue">搜索</el-button>
        </div>
        <div class="table">
          <el-table
            :data="tableData"
            ref="multipleTable"
            @selection-change="handleSelectionChange"
            @row-click="clickRow"
            style="width: 100%"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              prop="fileName"
              label="课件名称"
              show-overflow-tooltip
              align="center"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="上传时间"
              show-overflow-tooltip
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="fileExt"
              label="课件格式"
              show-overflow-tooltip
              align="center"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="filePath"
              label="课件地址"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
          </el-table>
          <pagination
            v-if="totalCount > 0"
            :pageSize="form.pageSize"
            :total="totalCount"
            @pageChange="pageVal"
          ></pagination>
        </div>
      </div>
      <div class="dialog_submit">
        <button class="btn btn_blue" @click="onsubmit()">提交</button>
      </div>
    </el-form>
  </div>
</template>

<script>
import pagination from "@/components/backStageComponent/pagination";
import API from "@/apis/backStageEndAPI/coursewareManagementAPI";
import CommonUtils from "@/utils";
export default {
  name: "selectCourseware",
  props: ["current", "type", "index"],
  components: {
    pagination,
  },
  data() {
    return {
      form: {
        startTime: "", //开始时间
        endTime: "", //结束时间
        keyword: "", //关键字
        fileGroup: 2, //0|其他，1|课件文档库，2|课件视频库
        page: 1, //页码
        pageSize: 10, //分页大小
      },
      totalCount: 0,
      formData: {},
      tableData: [],
      handleSelectionList: [],
    };
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    getTableList() {
      //获取文件列表
      API.getFileList(CommonUtils.parseToParams(this.form)).then((res) => {
        this.tableData = res.dataList;
        this.totalCount = res.rowCount;
      });
    },
    onsubmit() {
      //提交
      if (this.formData == "") {
        this.$message.error("请选择课件后再提交");
      } else {
        this.$emit(
          "getCourseware",
          this.formData,
          this.current,
          this.type,
          this.index
        );
      }
    },
    clickRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
      this.handleSelectionChange(this.handleSelectionList);
    },
    handleSelectionChange(selection) {
      //复选框实现只能单选
      this.handleSelectionList = selection;
      if (selection.length === 1) {
        this.formData = selection[0];
      } else if (selection.length > 1) {
        let del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      } else {
        this.formData = {};
      }
    },
    pageVal(val) {
      //分页跳转
      this.form.page = val;
      this.getTableList();
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/css/backStageEndCSS/myCoursePageMenu";
</style>
