<template>
  <div class="container">
    <header-bar>
      <template #search>
        <div class="wxq_left_search">
          <el-input
            placeholder="请输入关键字"
            prefix-icon="el-icon-search"
            v-model="input2"
          >
          </el-input>
          <el-button class="btn btn_blue">搜索</el-button>
        </div>
      </template>
    </header-bar>
    <div class="tabs">
      <el-tabs
        v-model="formData.courseType"
        type="card"
        @tab-click="handleClick"
      >
        <el-tab-pane label="点播" name="2"></el-tab-pane>
        <el-tab-pane label="直播" name="1"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="wxq_course_header">
      <div class="wxq_course_header_left">
        <el-form class="form" :inline="true">
          <el-row>
            <el-col :span="24">
              <el-form-item class="wxq_label" :label="label.medicalLabel">
                <div class="wxq_classify">
                  <span
                    class="wxq_classify_item"
                    :class="{ wxq_classify_active: allStatusSubject === 0 }"
                    @click="clickSubjectMenu('oneAll')"
                  >
                    全部
                  </span>
                  <span
                    class="wxq_classify_item"
                    :class="{
                      wxq_classify_active: item.id == clickSubjectStatus,
                    }"
                    v-for="item in subjectList"
                    :key="item.id"
                    @click="clickSubjectMenu('oneItem', item)"
                  >
                    {{ item.name }}
                  </span>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item class="wxq_label" :label="label.courseLabel">
                <div class="wxq_classify">
                  <span
                    class="wxq_classify_item"
                    :class="{ wxq_classify_active: allStatusCourseLabel === 0 }"
                    @click="clickCourseLabelMenu('oneAll')"
                  >
                    全部
                  </span>
                  <span
                    class="wxq_classify_item"
                    :class="{
                      wxq_classify_active:
                        item.dictLabel == clickCourseLabelStatus,
                    }"
                    v-for="item in courseLabelList"
                    :key="item.dictCode"
                    @click="clickCourseLabelMenu('oneItem', item)"
                  >
                    {{ item.dictLabel }}
                  </span>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item class="wxq_courses_type" label="课程名称">
                <el-input
                  clearable
                  v-model="formData.keyword"
                  placeholder="请输入课程名称"
                />
                <!--                <el-radio-group-->
                <!--                  v-model="formData.courseType"-->
                <!--                  @change="getCourseType"-->
                <!--                >-->
                <!--                  <el-radio :label="2">点播</el-radio>-->
                <!--                  <el-radio :label="1">直播</el-radio>-->
                <!--                </el-radio-group>-->
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="课程状态">
                <el-select
                  v-model="formData.state"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in courseState"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="创建时间">
                <!--                <el-date-picker-->
                <!--                  clearable-->
                <!--                  :picker-options="beginTimeOption"-->
                <!--                  v-model="formData.createStartTime"-->
                <!--                  type="datetime"-->
                <!--                  value-format="yyyy-MM-dd HH:mm:ss"-->
                <!--                  placeholder="选择开始时间"-->
                <!--                >-->
                <!--                </el-date-picker>-->
                <el-date-picker
                  class="wxq_time"
                  v-model="timeValue"
                  type="datetimerange"
                  placement="bottom-start"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <!--            <el-col :span="5">-->
            <!--              <el-form-item label="结束时间">-->
            <!--                <el-date-picker-->
            <!--                  clearable-->
            <!--                  :picker-options="endTimeOption"-->
            <!--                  v-model="formData.createEndTime"-->
            <!--                  type="datetime"-->
            <!--                  value-format="yyyy-MM-dd HH:mm:ss"-->
            <!--                  placeholder="选择结束时间"-->
            <!--                >-->
            <!--                </el-date-picker>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <!--<el-col :span="6">
              <el-form-item label="排序形式">
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in courseState"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>-->
            <el-col :span="4">
              <el-button class="btn btn_blue btn_search" @click="search"
                >搜索</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="wxq_course_header_right">
        <div class="wxq_count">
          <div class="wxq_title">
            {{ formData.courseType == 2 ? "点播总数" : "直播总数" }}
          </div>
          <div class="wxq_count_number color_blue">
            <span class="wxq_number">{{ rowCount }}</span>
            <span>节</span>
          </div>
          <div
            class="wxq_construction btn_orange box_shadow"
            @click="getAddCurriculum"
          >
            <i class="el-icon-plus"></i>
            <span>新建课程</span>
          </div>
        </div>
      </div>
    </div>
    <course-main
      v-if="dataList.length > 0"
      :dataList="dataList"
      :totalCount="totalCount"
      @getCompile="getCompile"
      @updateCourse="updateCourse"
      @pageChange="pageChange"
      :pageSize="formData.pageSize"
      :isPageFlag="isPageFlag"
    ></course-main>
    <none-data v-else @showNewBuild="showNewBuild"></none-data>
    <el-dialog
      class="dialog_wrapper"
      :title="createTitle"
      v-if="isCurriculum"
      :visible.sync="isCurriculum"
      :modal-append-to-body="false"
      top="10vh"
      width="650px"
    >
      <create-curriculum
        :id="currentId"
        :subjectTreeList="subjectTreeList"
        :courseLabelList="courseLabelList"
        @closeDialog="getCloseDialog"
      ></create-curriculum>
    </el-dialog>
  </div>
</template>

<script>
import noneData from "@comps/backStageComponent/noneData";
import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";
import courseMain from "./component/courseMain";
import createCurriculum from "./component/createCurriculum";
import API from "@/apis/backStageEndAPI/myCourseAPI";
import subjectAPI from "@/apis/backStageEndAPI/medicalManagementAPI";
import dictionaryAPI from "@/apis/backStageEndAPI/dictionaryManagementAPI";
import CommonUtils from "@/utils";
export default {
  name: "myCourses",
  components: {
    headerBar,
    courseMain,
    createCurriculum,
    noneData,
  },
  data() {
    return {
      formData: {
        courseType: "2", //课程类型：1|直播课，2|章节录播课
        page: 1, //页码
        pageSize: 12, //	分页大小
        createStartTime: "", //创建开始时间
        createEndTime: "", //创建截止时间
        keyword: "", //模糊查询
        labels: "", //标签，多标签逗号拼接
        state: "", //课程状态
        subjectId: "", //学科id
      },
      courseState: [
        //课程状态
        { id: 0, label: "未发布" },
        { id: 1, label: "已发布" },
        { id: 2, label: "待审核" },
        { id: 3, label: "已驳回" },
        { id: 9, label: "已下架" },
      ],
      timeValue: [],
      dataList: [], //课程列表
      totalCount: "", //课程总数
      rowCount: "",
      createTitle: "新建课程",
      currentId: "",
      input2: "",
      isCurriculum: false,
      allStatusSubject: 0,
      allStatusCourseLabel: 0,
      subjectTreeList: [], //学科树形列表
      subjectList: [], //学科列表
      courseLabelList: [], //课程标签列表
      clickSubjectStatus: "", //学科状态
      clickCourseLabelStatus: "", //课程标签状态
      beginTimeOption: {
        disabledDate: (time) => {
          let delay = this.formData.createEndTime;
          if (delay) {
            return time.getTime() > new Date(delay).getTime() - 8.64e7;
          }
        },
      },
      endTimeOption: {
        disabledDate: (time) => {
          let delay = this.formData.createStartTime;
          if (delay) {
            return time.getTime() < new Date(delay).getTime() + 8.64e7;
          }
        },
      },
      label: {},
      isPageFlag: "", //分页标识
    };
  },
  mounted() {
    this.label = this.$store.state.label;
    this.getSubjectList();
    this.getCourseLabel();
    this.getCourseList(true);
  },
  methods: {
    showNewBuild(bool) {
      this.isCurriculum = bool;
    },
    getCourseList(flag) {
      //获取课程列表
      this.formData.createStartTime = this.timeValue
        ? this.timeValue[0] || ""
        : "";
      this.formData.createEndTime = this.timeValue
        ? this.timeValue[1] || ""
        : "";

      // this.formData.createStartTime == null
      //   ? (this.formData.createStartTime = "")
      //   : this.formData.createStartTime;
      // this.formData.createEndTime == null
      //   ? (this.formData.createEndTime = "")
      //   : this.formData.createEndTime;
      API.getCourseList(CommonUtils.parseToParams(this.formData)).then(
        (res) => {
          res.dataList.map((item) => {
            switch (item.state) {
              case 0:
                item.state = "未发布";
                break;
              case 1:
                item.state = "已发布";
                break;
              case 2:
                item.state = "待审核";
                break;
              case 3:
                item.state = "已驳回";
                break;
              case 9:
                item.state = "已下架";
            }
          });
          this.dataList = res.dataList;
          this.totalCount = res.rowCount;
          if (flag) {
            this.rowCount = res.rowCount;
          }
        }
      );
    },
    handleClick(val) {
      this.formData.courseType = val.name;
      this.formData.subjectId = "";
      this.formData.labels = "";
      this.allStatusSubject = 0;
      this.allStatusCourseLabel = 0;
      this.clickSubjectStatus = "";
      this.clickCourseLabelStatus = "";
      this.getCourseList(true);
    },
    // getCourseType(val) {
    //   //切换直播、点播列表
    //   this.formData.courseType = val;
    //   this.formData.subjectId = "";
    //   this.formData.labels = "";
    //   this.allStatusSubject = 0;
    //   this.allStatusCourseLabel = 0;
    //   this.clickSubjectStatus = "";
    //   this.clickCourseLabelStatus = "";
    //   this.getCourseList(true);
    // },
    search() {
      //搜索
      this.formData.page = 1;
      this.isPageFlag = Math.floor(Math.random() * 1000);
      this.getCourseList();
    },
    getSubjectList() {
      //获取医学学科
      let status = "1"; //0停用，1正常
      subjectAPI.getList(status).then((res) => {
        this.subjectTreeList = res;
        res.map((item) => {
          this.subjectList.push({
            id: item.medicalId,
            name: item.medicalName,
          });
        });
      });
    },
    getCourseLabel() {
      //获取课程标签
      dictionaryAPI.getDicData("course_type").then((res) => {
        this.courseLabelList = res;
      });
    },
    getAddCurriculum() {
      //新建课程
      this.currentId = "";
      this.createTitle = "新建课程";
      this.isCurriculum = true;
    },
    getCompile(id) {
      //编辑课程
      this.isCurriculum = true;
      this.createTitle = "编辑课程";
      this.currentId = id;
    },
    updateCourse() {
      this.getCourseList();
    },
    getCloseDialog() {
      //关闭弹框
      this.isCurriculum = false;
      this.getCourseList();
    },
    pageChange(val) {
      this.formData.page = val;
      this.getCourseList();
    },
    clickSubjectMenu(type, data) {
      if (type == "oneAll") {
        this.allStatusSubject = 0;
        this.clickSubjectStatus = "";
        this.formData.subjectId = "";
        this.getCourseList();
      } else {
        this.allStatusSubject = "";
        this.clickSubjectStatus = data.id;
        this.formData.subjectId = data.id;
        this.getCourseList();
      }
    },
    clickCourseLabelMenu(type, data) {
      if (type == "oneAll") {
        this.allStatusCourseLabel = 0;
        this.clickCourseLabelStatus = "";
        this.formData.labels = "";
        this.getCourseList();
      } else {
        this.allStatusCourseLabel = "";
        this.clickCourseLabelStatus = data.dictLabel;
        this.formData.labels = data.dictLabel;
        this.getCourseList();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  color: #7d8592;
  font-weight: 800;
  .tabs {
    width: 312px;
  }
  .btn_search {
    margin-left: 16px;
  }
  .wxq_course_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    padding: 24px;
    width: 100%;
    border-radius: 24px;
    background: #fff;
    .wxq_course_header_left {
      position: relative;
      padding-right: 20px;
      // width: 82%;
      height: 100%;
      font-size: 14px;
      flex: 1;
    }
    .wxq_course_header_left:before {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 10%;
      left: auto;
      width: 1px;
      height: 80%;
      background-color: #f1f1f1;
      content: "";
    }
    .wxq_course_header_right {
      position: relative;
      padding: 0 16px;
      width: 240px;
      height: inherit;
      text-align: center;
      .wxq_count {
        position: relative;
        width: 100%;
        height: 90%;
        min-height: 160px;
        border-radius: 24px;
        background: #f4f9fd;
        text-align: center;
        .wxq_title {
          padding-top: 12px;
          color: #666;
        }
        .wxq_count_number {
          position: relative;
          top: 12%;
          height: 48px;
          font-weight: 800;
          font-size: 16px;
          line-height: 48px;
          .wxq_number {
            margin-right: 6px;
            font-size: 60px;
          }
        }
        .wxq_construction {
          position: absolute;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 48px;
          border-radius: 14px;
          cursor: pointer;
          span {
            color: #fff;
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
          }
          .el-icon-plus:before {
            margin-right: 13px;
            color: #fff;
            font-weight: 800;
            font-size: 20px;
          }
        }
      }
    }
    .form {
      .wxq_label {
        ::v-deep .el-form-item__label {
          line-height: 28px;
        }
      }
      ::v-deep .el-form-item {
        margin-bottom: 10px;
      }
      ::v-deep .el-form-item__content {
        line-height: normal;
      }
      .wxq_classify {
        .wxq_classify_item {
          display: inline-block;
          margin: 0 10px 10px 0;
          padding: 4px 10px;
          border-radius: 7px;
          background: #f4f9fd;
          text-align: center;
          font-size: 14px;
          cursor: pointer;
        }
        .wxq_classify_active {
          background: var(--colorBlue);
          color: #fff;
          font-weight: 600;
        }
      }
      .wxq_courses_type {
        margin-bottom: 0;
        .el-radio-group {
          line-height: 50px;
        }
      }
      .wxq_time {
        width: auto;
      }
    }
  }
}
::v-deep .el-dialog__wrapper {
  .el-dialog {
    .el-dialog__body {
      padding: 30px 30px 30px 10px;
      text-align: left;
      // max-height: 680px;
      // overflow-y: scroll;
    }
  }
}
::v-deep .hh_container {
  .hh_wrap {
    padding-top: 7%;
  }
}
</style>
