<template>
  <div class="wxq_content">
    <div class="wxq_course_main">
      <div class="wxq_course_detail" v-for="item in dataList" :key="item.id">
        <img :src="item.coverUrl" alt="" />
        <p class="wxq_detail_title">
          {{ item.courseTitle }}
        </p>
        <p class="wxq_detail_time">
          由&nbsp;
          <span>{{ item.creatorName | filterName }}</span>
          创建于&nbsp;
          <span>{{ item.createTime }}</span>
        </p>
        <div class="wxq_detail_footer">
          <div class="wxq_footer_box" v-if="item.state == '未发布'">
            <p>0</p>
            <p>观看人数</p>
          </div>
          <div class="wxq_footer_box" v-else>
            <p>{{ item.viewCount ? item.viewCount : 0 }}</p>
            <p>观看人数</p>
          </div>
          <div class="wxq_footer_box">
            <p>{{ item.courseType == 1 ? "直播" : "点播" }}</p>
            <p>课程类型</p>
          </div>
          <div @click="toCheck" class="wxq_footer_box hh_style">
            <p>{{ item.state }}</p>
            <p>课程状态</p>
          </div>
          <div class="wxq_footer_box">
            <p>{{ item.labels }}</p>
            <p>{{ label.courseLabel }}</p>
          </div>
        </div>
        <div class="wxq_hide_menu">
          <div class="wxq_menu_box" @click="getPageMenu(item)">
            <img
              src="@/assets/img/backStageImg/myCourse/page-menu-icon.png"
              alt=""
            />
            <p>页面菜单</p>
          </div>
          <div class="wxq_menu_box" @click="getWatchControl(item)">
            <img
              src="@/assets/img/backStageImg/myCourse/watch-control-icon.png"
              alt=""
            />
            <p>观看控制</p>
          </div>
          <div class="wxq_menu_box" @click="getDataStatistics(item)">
            <img
              src="@/assets/img/backStageImg/myCourse/data-statistics-icon.png"
              alt=""
            />
            <p>数据统计</p>
          </div>
          <div
            class="wxq_menu_box"
            v-if="item.courseType == 2"
            @click="getCourseSection(item)"
          >
            <img
              src="@/assets/img/backStageImg/myCourse/section-icon.png"
              alt=""
            />
            <p>章节目录</p>
          </div>
        </div>
        <!--基础设置-->
        <div class="wxq_basic_setting">
          <el-dropdown
            trigger="click"
            @command="handleCommand($event, item.courseId, item)"
          ><!-- .state -->
            <span class="el-dropdown-link">
              <!-- 基础设置 -->
              <i class="el-icon-more el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                command="compile"
                :disabled="item.state == '已发布' || item.state == '待审核'"
              >
                <img
                  src="@/assets/img/backStageImg/myCourse/compile-icon.png"
                  alt=""
                />
                编辑
              </el-dropdown-item>
              <el-dropdown-item
                command="publish"
                :disabled="item.state == '已发布' || item.state == '待审核'"
              >
                <img
                  src="@/assets/img/backStageImg/myCourse/send-icon.png"
                  alt=""
                />
                提交审核
              </el-dropdown-item>
              <el-dropdown-item command="history">
                <img src="@/assets/img/backStageImg/history-icon.png" alt="" />
                审核记录
              </el-dropdown-item>
              <el-dropdown-item
                command="undercarriage"
                :disabled="
                  item.state == '已下架' ||
                  item.state == '已驳回' ||
                  item.state == '未发布'
                "
              >
                <img
                  src="@/assets/img/backStageImg/myCourse/sold-out-icon.png"
                  alt=""
                />
                下架
              </el-dropdown-item>
              <el-dropdown-item
                command="delete"
                :disabled="item.state == '已发布' || item.state == '待审核'"
              >
                <img
                  src="@/assets/img/backStageImg/myCourse/delete-icon.png"
                  alt=""
                />
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <el-dialog
        class="dialog_wrapper"
        title="课程章节"
        v-if="isCourseSection"
        :visible.sync="isCourseSection"
        :modal-append-to-body="false"
        top="10vh"
        width="650px"
      >
        <course-section
          :id="courseId"
          :data="currentData"
          @closeDialog="getCloseDialog"
        ></course-section>
      </el-dialog>
    </div>
    <pagination
      v-if="totalCount > 0"
      :pageSize="pageSize"
      :total="totalCount"
      @pageChange="pageChange"
      ref="pageRef"
    />
    <!--审核记录-->
    <el-drawer
      :visible.sync="isDrawerVisible"
      :with-header="false"
      :wrapperClosable="true"
    >
      <div class="gl_drawer">
        <div class="gl_drawer_title">
          <span
            class="gl_state"
            :class="{
              color_blue: currentStateName == '待审核',
              color_orange: currentStateName == '审核中',
              color_green: currentStateName == '已发布',
              color_red: currentStateName == '已驳回',
              color_gray: currentStateName == '已下架' || '未发布',
            }"
            >{{ currentStateName }}</span
          >
          <h2 :title="lecturerTitle">{{ lecturerTitle }}</h2>
        </div>
        <div class="gl_drawer_cont">
          <p class="gl_drawer_t">审批历史</p>
          <ul v-if="auditCount > 0">
            <li v-for="item in auditList" :key="item.logId">
              <span class="gl_audit_user">{{ item.operatorName }}</span
              >{{ item.operation
              }}<span class="gl_audit_time">{{ item.operateTime }}</span>
              <p v-if="item.remark" class="gl_audit_info">
                "{{ item.remark }}"
              </p>
            </li>
          </ul>
          <p v-else class="gl_empty">——— 暂无审批数据 ———</p>
        </div>
        <pagination
          class="hh_page"
          v-if="auditCount > recordData.pageSize"
          :pageSize="recordData.pageSize"
          :total="auditCount"
          @pageChange="auditPageChange"
          ref="child"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Pagination from "@/components/backStageComponent/pagination";
import courseSection from "@views/backStageEnd/myCourses/component/courseSection";
import API from "@/apis/backStageEndAPI/myCourseAPI";
import CommonUtils from "@utils";
export default {
  name: "courseMain",
  props: ["dataList", "totalCount", "pageSize",'isPageFlag'],
  components: {
    courseSection,
    Pagination,
  },
  data() {
    return {
      isCourseSection: false, //章节目录弹框
      isDrawerVisible: false, //审核历史开关
      courseId: null, //课程id
      label: {},
      currentData: {},
      currentRow: "",
      currentStateName: "",
      lecturerTitle: "",
      auditCount: 0,
      auditList: [],
      recordData: {
        itemId: "",
        itemType: 0, //课程
        page: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.label = this.$store.state.label;
  },
  watch: {
    isPageFlag: {
      handler(val) {
        if(val) {
          this.$refs.pageRef.page = 1
        }
      }
    }
  },
  methods: {
    toCheck() {
      this.$emit("showChecks", true);
    },
    handleCommand(val, id,  item) {//state,
      //基础设置
      if (val == "compile") {
        //编辑
        this.$emit("getCompile", id);
      } else if (val == "publish") {
        //发布
        this.$confirm("确定要提交审核该课程吗？", {})
          .then((res) => {
            let params = {
              courseId: id,
              state: 1,
            };
            this.updateCourse(params);
          })
          .catch(() => {});
      } else if (val == "history") {
        this.getAuditLogList(item);
      } else if (val == "undercarriage") {
        //下架
        this.$confirm("确定要下架该课程吗？", {})
          .then((res) => {
            let params = {
              courseId: id,
              state: 9,
            };
            this.updateCourse(params);
          })
          .catch(() => {});
      } else if (val == "delete") {
        this.$confirm("确定要删除该课程吗？", {})
          .then((res) => {
            this.deleteCourse(id);
          })
          .catch(() => {});
      }
    },
    async getAuditLogList(item) {
      //获取审核记录
      this.currentRow = item;
      this.currentStateName = item.state;
      this.lecturerTitle = item.courseTitle;
      this.recordData.itemId = item.courseId;
      let data = await API.getAuditLogList(
        CommonUtils.parseToParams(this.recordData)
      );
      this.auditList = data.dataList;
      this.auditCount = data.rowCount;
      this.isDrawerVisible = true;
    },
    auditPageChange(val) {
      //审核记录页面跳转
      this.recordData.page = val;
      this.getAuditLogList(this.currentRow);
    },
    deleteCourse(id) {
      API.delCourse(id).then((res) => {
        this.$emit("updateCourse");
      });
    },
    updateCourse(params) {
      API.updateCourse(params).then((res) => {
        this.$emit("updateCourse");
      });
    },
    pageChange(val) {
      //页面跳转
      this.$emit("pageChange", val);
    },
    getPageMenu(val) {
      //跳转页面菜单
      let _this = this;
      this.$router.push({
        path: "/pageMenu",
        query: {
          data: JSON.stringify(val),
          fusion: JSON.stringify({
            from: _this.$store.state.fusion.from,
            path: "/pageMenu",
            token: _this.$store.state.fusion.token,
          }),
        },
      });
    },
    getWatchControl(val) {
      //跳转观看控制
      this.$router.push({
        path: "/watchTheControl",
        query: {
          data: JSON.stringify(val),
          cType: val.courseType,
        },
      });
    },
    getDataStatistics(val) {
      //跳转数据统计
      this.$router.push({
        path: "/dataStatistics",
        query: {
          data: JSON.stringify(val),
        },
      });
    },
    getCourseSection(val) {
      //获取章节目录
      this.courseId = val.courseId;
      this.currentData = val;
      this.isCourseSection = true;
    },
    getCloseDialog() {
      this.isCourseSection = false;
    },
  },
  filters: {
    filterName(value) {
      if (!value) return "";
      return value.length > 20 ? value.substr(0, 20) + "..." : value;
    },
  },
};
</script>

<style scoped lang="scss">
.wxq_content {
  padding-bottom: 20px;
  .color_gray {
    color: #dcdcdc;
  }
  .wxq_course_main {
    display: flex;
    flex-wrap: wrap;
    .wxq_course_detail {
      position: relative;
      overflow: hidden;
      margin: 0 8px 18px;
      padding: 10px;
      width: 368px;
      height: 346px;
      border-radius: 24px;
      background: #fff;
      box-shadow: 0 6px 58px 0 rgba(196, 203, 214, 0.1);
      img {
        width: 100%;
        height: 214px;
        border-radius: 24px;
      }
      .wxq_detail_title {
        overflow: hidden;
        margin-top: 8px;
        padding-left: 10px;
        color: #333;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 800;
        font-size: 16px;
      }
      .wxq_detail_time {
        display: flex;
        margin-bottom: 8px;
        padding-left: 10px;
        line-height: 26px;
        span:first-of-type {
          display: inline-block;
          overflow: hidden;
          width: 50px;
          color: var(--colorBlue);
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .wxq_detail_footer {
        display: flex;
        // margin-top: 12px;
        height: 72px;
        .wxq_footer_box {
          padding: 0 8px;
          width: 24%;
          height: 42px;
          border-right: 1px solid #f1f1f1;
          text-align: center;
          p {
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          p:first-of-type {
            color: var(--colorBlue);
            font-size: 16px;
          }
          p:last-of-type {
            font-size: 12px;
            line-height: 24px;
          }
        }
        .hh_style {
          cursor: pointer;
        }
        .wxq_footer_box:last-of-type {
          border: none;
        }
      }
      .wxq_hide_menu {
        display: flex;
        justify-content: space-evenly;
        height: 70px;
        transition: all 0.2s linear 0s;
        .wxq_menu_box {
          overflow: hidden;
          padding: 14px 15px 0 15px;
          text-align: center;
          cursor: pointer;
          img {
            width: 24px;
            height: 20px;
          }
          p {
            line-height: 22px;
          }
        }
        .wxq_menu_box:hover {
          color: var(--colorBlue);
          img {
            filter: drop-shadow(var(--colorBlue) 0 50px);
            transform: translateY(-50px);
          }
        }
      }
      .wxq_basic_setting {
        position: absolute;
        top: 22px;
        right: 22px;
        padding: 8px 0 8px 9px;
        width: 35px;
        height: 36px;
        border-radius: 12px;
        background: rgba(0, 0, 0, 0.5);
        box-shadow: 0 6px 58px 0 rgba(196, 203, 214, 0.4);
        cursor: pointer;
        .el-dropdown {
          color: #fff;
          font-weight: 400;
          .el-icon--right {
            margin-left: 2px;
            font-size: 16px;
            transform: rotate(90deg);
          }
        }
      }
    }
  }
  .wxq_course_detail:hover {
    .wxq_detail_footer {
      transform: translateY(90px);
    }
    .wxq_hide_menu {
      transform: translateY(-90px);
    }
  }
  .pagination {
    padding-top: 0;
  }

  .gl_drawer {
    width: 100%;
    box-sizing: border-box;
    .gl_drawer_title {
      position: relative;
      padding: 0 40px 0 70px;
      background: var(--colorBlue);
      color: #fff;
      text-align: center;
      font-size: 16px;
      line-height: 40px;
      h2 {
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .gl_state {
        position: absolute;
        top: 50%;
        left: 8px;
        padding: 4px 8px;
        border-radius: 16px;
        background: #fff;
        font-size: 12px;
        line-height: 100%;
        transform: translateY(-50%);
      }
    }
    .gl_empty {
      color: #666;
      text-align: center;
    }
    li {
      margin-bottom: 16px;
      padding: 8px;
      background: #f7faff;
      .gl_audit_user {
        padding-right: 6px;
        color: var(--colorBlue);
      }
      .gl_empty {
        color: #666;
        text-align: center;
      }
      li {
        margin-bottom: 16px;
        padding: 8px;
        background: #f7faff;
        .gl_audit_user {
          padding-right: 6px;
          color: var(--colorBlue);
        }
        .gl_audit_time {
          float: right;
          color: #666;
        }
        .gl_audit_info {
          padding: 4px;
        }
      }
    }
  }
}
::v-deep .el-dropdown-menu__item {
  display: flex;
  align-items: center;
  img {
    margin-right: 6px;
    width: 22px;
  }
}
</style>
