<template>
  <!--课程章节-->
  <div class="wxq_container">
    <el-form class="form" :inline="true">
      <el-row class="wxq_lunch">
        <el-col :span="24">
          <el-form-item label="课程章节">
            <div class="wxq_addition bg_blue box_shadow" @click="getAddSection"
                 v-if="data && (data.state == '已下架' || data.state == '已驳回' || data.state == '未发布')">
              <i class="el-icon-plus"></i>
              添加章节
            </div>
            <div class="wxq_section" v-for="(item,index) in section" :key="index">
              <div class="wxq_section_catalog">
                <div class="wxq_delete" v-if="section.length > 1" @click="getDeleteCatalog(index)"><i
                  class="el-icon-close"></i></div>
                <div class="wxq_catalog">{{ item.chapterTitle }}</div>
                <div class="wxq_courseware">
                  <el-button class="bg_lightGreen" @click="getSelectCourseware(index,item.type)" :disabled="item.unit">
                    {{ item.selectCourseware }}
                  </el-button>
                  <el-input v-model.trim="item.chapterName" maxlength="120" placeholder='选择课件后可修改章节标题'
                            show-word-limit></el-input>
                </div>
              </div>
              <div class="wxq_section_add">
                <span>在此章节中添加单元</span>
                <el-switch
                  v-model="item.unit"
                  :disabled="item.fileId != ''"
                  active-color="#3F8CFF"
                  inactive-color="#CED5E0"
                  @change="clearUnit($event,item)">
                </el-switch>
              </div>
              <template v-if="item.unit">
                <div class="wxq_unit_directory" v-for="(i,inx) in item.children" :key="inx">
                  <div class="wxq_delete" v-if="item.children.length > 1" @click="getDeleteUnit(index,inx)"><i
                    class="el-icon-close"></i></div>
                  <div class="wxq_unit">{{ i.chapterTitle }}</div>
                  <div class="wxq_courseware">
                    <el-button class="bg_lightGreen" @click="getSelectCourseware(inx,i.type,index)">
                      {{ i.selectCourseware }}
                    </el-button>
                    <el-input v-model.trim="i.chapterName" maxlength="120" placeholder='选择课件后可修改标题'
                              show-word-limit></el-input>
                    <div class="wxq_add_unit bg_blue box_shadow" @click="getAddUnit(index)">
                      <i class="el-icon-plus"></i>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="data && (data.state == '已下架' || data.state == '已驳回' || data.state == '未发布')">
        <el-col :span="24" class="dialog_submit">
          <el-button class="btn btn_blue" @click="getSave">保存</el-button>
          <!--          <el-button class="btn btn_blue">保存并发布</el-button>-->
        </el-col>
      </el-row>
      <el-dialog
        class="dialog_wrapper"
        title="选择课件"
        v-if="isSelectCourseware"
        :visible.sync="isSelectCourseware"
        :modal-append-to-body="true"
        append-to-body
        top="15vh"
        width="864px">
        <select-courseware :current="current" :index="index" :type="type"
                           @getCourseware="getCourseware"></select-courseware>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import selectCourseware from "@views/backStageEnd/myCourses/component/selectCourseware";
import {convertToChinaNum} from "@assets/js/convertChinese";
import API from "@/apis/backStageEndAPI/myCourseAPI";
export default {
  name: "courseSection",
  props: ['id', 'data'],
  components: {
    selectCourseware
  },
  data() {
    return {
      isSelectCourseware: false,//选择课件弹框开关
      type: '',
      current: '',
      index: '',
      section: [
        {
          courseId: this.id,
          chapterId: '',//章节ID
          chapterTitle: '章节一',//章节标题
          chapterName: '',//章节名称
          fileId: '',//课件ID
          filePath: '',//课件地址
          parentId: '',
          unit: false,//单元开关
          type: 'section',//类型：章节
          selectCourseware: '选择课件',//选择课件按钮
          children: [
            // {
            //   courseId: this.id,
            //   chapterId: '',
            //   chapterTitle: '单元一',//单元标题
            //   chapterName: '',//单元名称
            //   fileId: '',//课件ID
            //   filePath: '',//课件地址
            //   parentId: '',
            //   type: 'unit',//类型：单元
            //   selectCourseware: '选择课件'//选择课件按钮
            // }
          ],
        },
      ],
    }
  },
  mounted() {
    if (this.id) {
      this.getSectionList(this.id)
    }
  },
  methods: {
    getSectionList(id) {
      API.getChapterList(id).then(res => {
        if (res.length != 0) {
          this.section = res
        }
      })
    },
    clearUnit(val,item) {
      if (!val) {
        item.children = []
      }else {
        item.children = [{
          courseId: this.id,
          chapterId: '',
          chapterTitle: '单元一',//单元标题
          chapterName: '',//单元名称
          fileId: '',//课件ID
          filePath: '',//课件地址
          parentId: '',
          type: 'unit',//类型：单元
          selectCourseware: '选择课件'//选择课件按钮
        }]
      }
    },
    getSave() { //保存
      let that = this
      let flag = that.section.every(item => !!item.chapterName)
      let flag1 = that.section.every(item => {
        item.children.every(i => !!i.chapterName)
      })
      if (!flag && !flag1) {
        this.$message.warning('请填写完成课件名称再提交')
        return
      } else {
        API.getChapter(this.id, this.section).then(res => {
          this.$emit('closeDialog')
        })
      }
    },
    getSelectCourseware(index, type, inx) { //选择课件
      this.current = index
      this.type = type
      this.index = inx
      this.isSelectCourseware = true
    },
    getCourseware(val, current, type, ix) { //关闭选择课件
      if (type == 'section') {
        this.section.forEach((item, index) => {
          if (current == index) {
            item.selectCourseware = '重选课件'
            item.chapterName = val.fileName
            item.fileId = val.fileId
            item.filePath = val.filePath
            item.unit = false
          }
        })
      } else if (type == 'unit') {
        this.section.forEach((item, index) => {
          item.children.forEach((i, inx) => {
            if (inx == current && index == ix) {
              i.selectCourseware = '重选课件'
              i.chapterName = val.fileName
              i.fileId = val.fileId
              i.filePath = val.filePath
            }
          })
        })
      }
      this.isSelectCourseware = false
    },
    getAddSection() {//添加章节
      this.section.push({
        courseId: this.id,
        chapterId: '',//章节ID
        fileId: '',
        unit: false,
        chapterTitle: '章节' + convertToChinaNum(this.section.length + 1),
        chapterName: '',
        parentId: '',
        type: 'section',
        selectCourseware: '选择课件',
        children: [
          // {
          //   courseId: this.id,
          //   chapterId: '',//章节ID
          //   parentId: '',
          //   fileId: '',
          //   chapterTitle: '单元一',
          //   type: 'unit',
          //   selectCourseware: '选择课件'
          // }
        ],
      })
    },
    getAddUnit(val) { //添加单元
      this.section.forEach((item, index) => {
        if (val == index) {
          item.children.push({
            courseId: this.id,
            chapterId: '',//章节ID
            parentId: '',
            fileId: '',
            chapterTitle: '单元' + convertToChinaNum(item.children.length + 1),
            chapterName: '',
            key: Date.now(),
            type: 'unit',
            selectCourseware: '选择课件',
          })
        }
      })
    },
    getDeleteCatalog(val) { //删除章节
      this.section.splice(val, 1)
      this.section.forEach((item, index) => {
        item.chapterTitle = '章节' + convertToChinaNum(index + 1)
      })
    },
    getDeleteUnit(val, inx) { //删除单元
      this.section.forEach((item, index) => {
        if (val == index) {
          item.children.splice(inx, 1)
          item.children.forEach((i, ix) => {
            i.chapterTitle = '单元' + convertToChinaNum(ix + 1)
          })
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.wxq_container {
  .wxq_lunch {
    margin-top: 34px;
    .wxq_addition {
      position: absolute;
      top: -34px;
      right: 0;
      width: 90px;
      height: 24px;
      border-radius: 14px;
      color: #fff;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      cursor: pointer;
      .el-icon-plus {
        margin-left: 12px;
        font-weight: 800;
      }
    }
  }
  .wxq_section {
    margin-bottom: 18px;
    padding-bottom: 12px;
    border-radius: 14px;
    background: #f4f9fd;
    .is-disabled {
      background: #ced5e0 !important;
      border-radius: 10px;
    }
    //章节目录样式
    .wxq_section_catalog {
      position: relative;
      display: flex;
      height: 42px;
      .wxq_catalog {
        padding-left: 18px;
        //章节左侧样式
        width: 100px;
        border: 1px solid #d8e0f0;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 1px 2px 0 rgba(184, 200, 224, .22);
        color: #0a1629;
        font-weight: 400;
        font-size: 16px;
        line-height: 42px;
      }
      .wxq_courseware {
        display: flex;
        align-items: center;
        margin-left: -15px;
        padding: 6px;
        width: 464px;
        border: 1px solid #d8e0f0;
        border-radius: 14px;
        background: #fff;
        box-shadow: 0 1px 2px 0 rgba(184, 200, 224, .22);
        button {
          padding: 6px 12px;
          //章节选择课件样式
          width: 80px;
          height: 32px;
          border: none;
          border-radius: 9px;
          color: #fff;
          font-weight: 400;
        }
        ::v-deep .el-input {
          //章节input样式
          input {
            border: none;
            font-size: 14px;
          }
        }
      }
    }
    .wxq_section_add {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 24px;
      color: #0a1629;
      font-weight: 400;
      font-size: 16px;
    }
    //单元样式
    .wxq_unit_directory {
      display: flex;
      margin-bottom: 12px;
      padding: 0 24px;
      height: 42px;
      .wxq_unit {
        padding-left: 18px;
        //单元左侧样式
        width: 100px;
        border: 1px solid #d8e0f0;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 1px 2px 0 rgba(184, 200, 224, .22);
        color: #0a1629;
        font-weight: 400;
        font-size: 16px;
        line-height: 42px;
      }
      .wxq_courseware {
        display: flex;
        align-items: center;
        margin-left: -15px;
        padding: 6px 0 6px 6px;
        width: 464px;
        border: 1px solid #d8e0f0;
        border-radius: 14px;
        background: #fff;
        box-shadow: 0 1px 2px 0 rgba(184, 200, 224, .22);
        button {
          padding: 6px 12px;
          //单元选择课件样式
          width: 80px;
          height: 32px;
          border: none;
          border-radius: 9px;
          color: #fff;
          font-weight: 400;
        }
        ::v-deep .el-input {
          //单元input样式
          width: 292px;
          input {
            border: none;
          }
        }
        .wxq_add_unit {
          padding: 5px 9px;
          width: 42px;
          height: 42px;
          border-radius: 14px;
          cursor: pointer;
          .el-icon-plus {
            color: #fff;
            font-weight: 800;
            font-size: 24px;
          }
        }
      }
    }
  }
  .wxq_delete {
    position: absolute;
    //删除章节单元斜三角样式
    width: 35px;
    height: 35px;
    border-top-left-radius: 10px;
    background: linear-gradient(315deg, transparent 50%, var(--colorBlue) 51%) no-repeat;
    background-size: 35px 35px;
    cursor: pointer;
    .el-icon-close {
      display: block;
      margin: 2px 0 0 4px;
      color: #fff;
      font-weight: 800;
      font-size: 16px;
    }
  }
}
</style>
