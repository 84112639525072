<template>
  <!--新建课程-->
  <div class="wxq_container">
    <el-form
      class="form"
      :model="formData"
      :rules="rules"
      ref="form"
      :inline="true"
    >
      <el-row>
        <el-col :span="24">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane
              label="点播"
              :disabled="isDisabled"
              name="bunch"
            ></el-tab-pane>
            <el-tab-pane
              label="直播"
              :disabled="isDisabled"
              name="live"
            ></el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :span="24">
          <el-form-item label="课程标题" prop="courseTitle">
            <el-input
              v-model.trim="formData.courseTitle"
              placeholder="请输入6-100位课程标题"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item class="wxq_upload" label="背景图片" prop="coverUrl">
            <up-loads
              :callbackList="coverUrl"
              :upStyle="'btnList'"
              ref="isShow"
              @up-success="upLoadImgUrls"
            ></up-loads>
            <ul class="wxq_explain">
              <li><p>建议图片大小2M以内</p></li>
              <li><p>建议图片分辨率865*486像素</p></li>
              <li><p>支持PNG、JPG、JPEG格式</p></li>
            </ul>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="label.medicalLabel" prop="subjectId">
            <el-cascader
              v-model="formData.subjectId"
              :options="subjectTreeList"
              :props="cascadeProps"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="label.courseLabel" prop="labels">
            <el-select v-model="formData.labels" placeholder="请选择">
              <el-option
                v-for="item in courseLabelList"
                :key="item.dictCode"
                :label="item.dictLabel"
                :value="item.dictLabel"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!--选择直播显示的内容-->
      <el-row v-if="isLive">
        <el-col :span="12">
          <el-form-item label="开播时间" prop="liveInfo.liveStartTime">
            <el-date-picker
              v-model="formData.liveInfo.liveStartTime"
              :picker-options="startTimeOption"
              type="datetime"
              placement="bottom-start"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开播倒计时" prop="liveInfo.liveNoticeBefore">
            <el-select
              v-model="formData.liveInfo.liveNoticeBefore"
              placeholder="请选择"
            >
              <el-option
                v-for="item in remindSetList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item class="wxq_remind">
            <span>直播录制</span>
            <span class="wxq_voluntarily">（开启后将自动录制内容）</span>
            <el-switch
              v-model="formData.liveInfo.liveRecordingState"
              active-color="#3F8CFF"
              inactive-color="#CED5E0"
              active-value="1"
              inactive-value="0"
            ></el-switch>
          </el-form-item>
        </el-col>
        <!--<el-col :span="24">
          <el-form-item class="wxq_remind">
            <span>开播提醒</span>
            <el-switch
              v-model="value"
              active-color="#3F8CFF"
              inactive-color="#CED5E0">
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="提醒设置" prop="">
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in remindSetList"
                :key="item.id"
                :label="item.label"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>-->
      </el-row>
      <el-row>
        <el-col :span="24" class="dialog_submit">
          <el-button class="btn btn_blue" @click="getSave('form')"
            >保存</el-button
          >
          <el-button
            class="btn btn_blue"
            v-show="activeName == 'live'"
            @click="getSave('form', 1)"
            >提交审核</el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import upLoads from "@/components/backStageComponent/upLoads";
import API from "@/apis/backStageEndAPI/myCourseAPI";
import CommonUtils from "@/utils";
export default {
  name: "createCurriculum",
  props: ["id", "subjectTreeList", "courseLabelList"],
  components: {
    upLoads,
  },
  data() {
    return {
      isDisabled: false,
      coverUrl: [],
      formData: {
        courseId: "", //课程id
        courseType: 2, //课程类型：1:直播，2:录播
        courseTitle: "", //课程标题
        coverUrl: "", //封面图
        subjectId: "", //医学学科
        labels: "", //课程标签
        liveInfo: {
          liveStartTime: "", //开播时间
          liveNoticeBefore: 0, //开播倒计时,
          liveRecordingState: 0, //是否开启直播录制
        },
        state: 0, //0|未发布，1|已发布，9|已下架
      },
      cascadeProps: {
        value: "medicalId",
        label: "medicalName",
        children: "children",
        expandTrigger: "hover",
        checkStrictly: true
      },
      activeName: "bunch", //直播录播切换
      isLive: false, //直播显示内容
      remindSetList: [
        { id: 1, label: "关闭" },
        { id: 0, label: "准时提醒" },
        { id: 30, label: "开播前30分钟" },
        { id: 60, label: "开播前60分钟" },
      ],
      type: "",
      rules: {
        courseTitle: [
          { required: true, message: "请输入课程标题", trigger: "blur" },
          { min: 6, max: 100, message: "请输入6-100位课程标题" },
        ],
        coverUrl: [
          { required: true, message: "请选择背景图片", trigger: "change" },
        ],
        subjectId: [{ required: true, message: "请选择", trigger: "change" }],
        labels: [{ required: true, message: "请选择", trigger: "change" }],
        liveInfo: {
          liveStartTime: [
            { required: true, message: "请选择开播时间", trigger: "change" },
          ],
        },
      },
      startTimeOption: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 8.64e7;
        },
      },
      label: {},
    };
  },
  mounted() {
    this.label = this.$store.state.label;
    if (this.id) {
      this.isDisabled = true;
      this.getCourseDetail(this.id);
    }
    if (this.subjectTreeList) {
      this.subjectTreeList.map((item, index) => {
        item.children.map((i, ix) => {
          if (i?.children?.length < 1) {
            i.children = undefined;
          }
        });
      });
    }
  },
  methods: {
    getCourseDetail(id) {
      //获取课程详情回显
      API.getCourseDetail(id).then((res) => {
        res.courseType === 1
          ? (this.activeName = "live")
          : (this.activeName = "bunch");
        this.isLive = this.activeName !== "bunch";
        this.formData.courseType = res.courseType;
        this.formData.courseTitle = res.courseTitle;
        this.formData.coverUrl = res.coverUrl;
        this.coverUrl = res.coverUrl.split(",");
        this.$refs.isShow.isShow = this.coverUrl.length === 0;
        this.formData.subjectId = res.subjectId;
        this.formData.labels = res.labels;
        this.formData.liveInfo.liveRecordingState =
          res.liveInfo.liveRecordingState.toString();
        this.formData.liveInfo.liveStartTime = res.liveInfo.liveStartTime;
        this.formData.liveInfo.liveNoticeBefore = res.liveInfo.liveNoticeBefore;
      });
    },
    getSave(formName, val) {
      //保存
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formData.courseType = this.activeName == "live" ? 1 : 2;
          this.formData.state = val ? val : 0;
          if (this.id) {
            //有id调编辑接口，无id调新建接口
            this.formData.courseId = this.id;
            API.updateCourse(this.formData).then((res) => {
              this.$emit("closeDialog");
            });
          } else {
            API.addCourse(this.formData).then((res) => {
              this.$emit("closeDialog");
            });
          }
        } else {
          return false;
        }
      });
    },
    handleChange(val) {
      this.formData.subjectId = val[val.length - 1];
    },
    upLoadImgUrls(val) {
      //上传背景图
      this.coverUrl = val;
      this.formData.coverUrl = val.join(",");
      this.$refs.isShow.isShow = this.coverUrl.length === 0;
    },
    handleClick(tab) {
      //切换直播点播
      this.isLive = tab.name !== "bunch";
      this.formData.courseType = tab.name === "live" ? 1 : 2;
    },
  },
};
</script>

<style scoped lang="scss">
.wxq_container {
  .wxq_remind {
    ::v-deep .el-form-item__content {
      display: flex !important;
      justify-content: space-between;
      margin-left: 20px;
      padding: 20px 24px;
      width: 580px;
      height: 79px;
      border-radius: 14px;
      background: #f4f9fd;
      span {
        font-size: 16px;
      }
      .wxq_voluntarily {
        margin-left: -224px;
        color: #7d8592;
        font-weight: normal;
      }
    }
    ::v-deep .el-form-item__content::after,
    ::v-deep .el-form-item__content::before {
      display: none;
    }
  }
  ::v-deep .el-form-item {
    width: 100%;
    .el-form-item__label {
      color: #7d8592;
      font-weight: 400;
      font-size: 14px;
    }
    .el-form-item__content {
      display: block;
      .el-cascader {
        width: 100%;
      }
      .el-input__inner {
        border-radius: 14px;
      }
    }
  }
  ::v-deep .el-tabs__header {
    margin: 0 auto 15px;
    padding: 4px 6px;
    width: 37%;
    height: 40px;
    border: none;
    border-radius: 24px;
    background: #e6edf5;
    text-align: center;
    .el-tabs__nav {
      border: none;
    }
    .el-tabs__item {
      width: 106px;
      height: 32px;
      border: none;
      color: #0a1629;
      font-size: 16px;
      line-height: 32px;
      transition: none;
    }
    .is-active {
      border-radius: 20px;
      background: var(--colorBlue);
      color: #fff;
    }
    .is-disabled {
      cursor: not-allowed;
    }
  }
  ::v-deep .el-switch {
    height: 40px;
    .el-switch__core {
      width: 50px !important;
      height: 30px;
      border-radius: 18px;
    }
    .el-switch__core:after {
      top: 3px;
      width: 22px;
      height: 22px;
    }
  }
  ::v-deep .el-switch.is-checked .el-switch__core::after {
    margin-left: -23px;
  }
  ::v-deep .wxq_upload {
    .el-form-item__content {
      display: flex;
      .wxq_explain {
        margin-left: 25px;
        padding-top: 14px;
        li {
          position: relative;
          line-height: 28px;
          p {
            margin-left: 12px;
          }
          p:before {
            position: absolute;
            top: 10px;
            left: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--colorBlue);
            content: "";
          }
        }
      }
    }
    .upLoad_list_big {
      width: 186px;
      height: 104px;
    }
  }
}
</style>
